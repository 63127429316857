.move-conversion-banner {
  display: flex;
  align-items: center;
  padding: var(--8px) var(--12px);
  justify-content: space-between;
  width: 100%;
  background: linear-gradient(90deg, rgba(255, 138, 0, 0.1) 0%, rgba(255, 138, 0, 0) 100%);
  margin-top: var(--26px);

  .move-conversion-banner__left {
    display: flex;
    align-items: center;
    gap: var(--16px);

    .move-conversion-banner__content {
      display: flex;
      flex-direction: column;

      h3 {
        @include LMedium;
      }
      p {
        @include SRegular;
      }
    }
  }
}
